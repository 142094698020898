@import "../node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600&display=swap');


// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;


@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }
 
 // Medium devices
 @mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }
 
 // Large devices
 @mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }
 
 // Extra large devices
 @mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

$oswald: 'Oswald', sans-serif;
#root {
    height: 100%;
}

.App {
    height: 100%;
}

html, body {
    height: 100%;
}

body {
    
    margin: 0;
    background-color: #202840;
    color: #fff;
    font-family: $oswald;
    font-weight: 400;
}

.welcome-modal-container {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    margin: 0;
    padding: 0;
}

.welcome-modal {
    color: rgb(32,40,64)
}


// STARS ANIMATION

.stars {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -10;
  }
  
  

  
  #btnDebug {
    position: fixed;
    z-index:1;
    top: 10px;
    left: 10px;
  }
  
  .star {
    fill: white;
    animation: pulse var(--star-animation-duration, 1000ms) infinite backwards,
      glowing var(--star-animation-glow-duration, 1000ms) infinite alternate backwards;
    animation-delay: var(--star-animation-delay, 0);
  }
  
  #starGroup {
    --dur: 100ms;
  }
  
  @keyframes pulse {
    0% {
      transform: scale3d(1, 1, 1);
    }
  
    30% {
      transform: scale3d(1.2, 1.2, 1.2);
    }
  
    70% {
      transform: scale3d(0.2, 0.2, 0.2);
    }
  
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
  
// Nav Bar
.site-title-main {
    font-family: $oswald;
    font-weight: 600;
}  

.main-nav-bar-container {
    justify-content: center;

    .nav-bar-left {
        margin-right: 10px;
        display: none !important;
        
        @include lg {
            margin-right: auto;
            visibility: hidden;
            display: flex !important;
        }
    
    }

    .nav-bar-right {
        margin-left: auto;

        a {
            font-family: $oswald;
            color: #fff;
            text-decoration: underline;
        }
    }
}

// Auth Pages

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-label {
    color: #fff;
    font-family: $oswald;
}

.form-control, .form-control:focus, .form-control:active {
    background: transparent;
    color: #fff;
    font-family: $oswald;
}

.register-button, .register-button:active, .register-button:focus, .register-button:hover {
    font-family: $oswald;
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    margin-top: 3rem;
    border-radius: 0px;
    outline: none;
} 

.form-info-bottom {
    * {
        color: #fff;

    }
}

// Home Page

.main-content-container {

   height: 85%; 

    .generate-photo-message {
        width: 85%;
        color: #fff;
        font-family: $oswald;
        text-transform: uppercase;
        margin-bottom: 3rem;
        text-align: center;
    }
    
    .main-image-container {
        height: 50%;
        width: 90%;
        margin: 3rem auto;
        position: relative;

        @include md {
            width: 85%;
        }
    
        @include lg {
            height: 40rem;
            width: 50%;
        }

        .add-to-favorites-icon {
            position: absolute;
            color: #fff;
            top: 10%;
            right: 5%;
            z-index: 3;
        }
    
        .main-image {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    
    }
    
    .generate-photo-button {
        font-family: $oswald;
        color: #fff;
        background-color: transparent;
        border: 2px solid #fff;
    }
    
}


// Favorites Page
.favorites-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;

    .favorite-item {
        position: relative;
        
        i {
            position: absolute;
            top: 5%;
            right: 5%;
            color: #fff;
            z-index: 30;
            cursor: pointer;
        }
    }

    .favorites-image {
        max-height: 400px;
        width: 100%;
        object-fit: contain;

        @include md {
            height: 100%;
            object-fit: fill;
        }
    }

}